import { Controller } from "@hotwired/stimulus"
import swal from 'sweetalert';

// Connects to data-controller="wait-animation"
export default class extends Controller {
  
  connect() {
    
  }

  alert(){
    const div = document.createElement("div")
    swal({
      title: 'Nous chargeons les visuels.',
      text: 'Cela ne prendra que quelques instants.',
      content: div,
      closeOnEsc: false,
      closeOnClickOutside: false,
      showSpinner: true,
      buttons: false,
    })

  }
}
