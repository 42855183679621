import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navbar"
export default class extends Controller {

  static targets = ["navlink"]

  connect() {
    const ref = window.location.pathname.replace('/','').split('/')[0];

    this.navlinkTargets.forEach(element => {
      element.classList.remove("activ")
      if (element.dataset.link === ref){
        element.classList.add("link-current-page")
      }
    });

    
    // console.log(this.targets)
  }


}
